import {
  Entity,
  GroupUser,
  GroupUserEntity,
} from 'processdelight-angular-components';
import { DepartmentUser } from './departmentUser';

export class Department extends Entity {
  name!: string;
  supervisorId?: string;
  supervisor?: GroupUserEntity;
  phoneNumber?: string;
  users?: DepartmentUser[] = [];
  isDeleted?: boolean = false;

  constructor(obj: Partial<Department>) {
    super(obj);
    Object.assign(this, obj);
  }
}
